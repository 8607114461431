import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 게시판관리 화면 관련 기능 클래스
 */
class Image {

    /**
     * 생성자
     */
    constructor() {
    }

    /**
     * 이미지 정보 목록 조회
     * @param {string} systemCode
     * @returns 
     */
     getImageInfoList(systemCode) {

        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz';
            request.methodId = 'GetImageInfo';
            request.parameters.systemCode = systemCode;


            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);

            }).catch(error => reject(error));
        });
    }

    /**
     *  이미지 정보 저장
     * @param {Object} imageDetail 
     * @returns 
     */
     saveImageInfo(imageDetail) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz';
            request.methodId = 'CreateImageInfo';

            request.parameters = imageDetail;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     *  이미지 정보 수정
     * @param {Object} imageDetail 
     * @returns 
     */
     updateImageInfo(imageDetail) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz';
            request.methodId = 'UpdateImageInfo';

            request.parameters = imageDetail;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     *  이미지 정보 삭제
     * @param {Object} imageDetail 
     * @returns 
     */
     deleteImageInfo(imageDetail) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz';
            request.methodId = 'DeleteImageInfo';

            request.parameters = imageDetail;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }
}

export const image = new Image();