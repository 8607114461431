<template>
    <div class="admin-content">
        <h3>메인 이미지 관리</h3>
        <div class="flex-wrap right">
            <div class="ml10">
                <div class="btn-wrap">
                    <button class="btn-box" @click="methods.getImageInfoList">조회</button>
                    <button class="btn-box green" @click="{
                        state.dialog.isOpen = true;
                        state.dialog.isNew = true;
                    }" >신규</button>
                </div>
            </div>
            <div>
				<div class="sch-wrap3">
					<div>
						<label>계정 유형</label>
						<div>
                            <div class="select-box">
							    <select v-model="state.dataTable.selectedSystemCode">
                                    <option v-for="item in state.selectBox.items" v-bind:value="item.CodeValue" v-bind:key="item" v-text="item.CodeName" />
                                </select>
                            </div>
						</div>
					</div>
				</div>
			</div>
        </div>

        <!-- 총 개수 -->
        <div class="total-box">
          <p>Total <strong>{{state.dataTable.ImageItems === null ? 0 : state.dataTable.ImageItems.length}}</strong></p>
        </div>

        <DataTable class="p-datatable-sm"
            columnResizeMode="fit"
            scrollHeight="497px"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :value="state.dataTable.ImageItems"
            :paginator="true"
            :rowHover="true"
            :rows="15"
            :resizableColumns="true" 
            @row-click="eventHandler.dataTable.onRowClick">
            
            <template #empty>
                데이터가 없습니다.
            </template>
          
            <Column field="SystCode" header="시스템코드" style="width:100px;" headerClass="tc"></Column>
            <Column field="ImageName" header="이미지명" style="width:200px;" headerClass="tc"></Column>
            <Column field="ImagePath" header="이미지 파일 경로" style="width:200px;" headerClass="tc"></Column>
            <Column field="Description" header="설명" style="width:200px;" headerClass="tc"></Column>
            <Column field="ContentMaintain" header="유지기간" style="text-align:center; width:100px;" headerClass="tc"></Column>
            
        </DataTable>

        <Dialog 
            :header="state.dialog.header"
            :visible="state.dialog.isOpen"
            :modal="true"
            @show="eventHandler.dialog.show"
            @hide="eventHandler.dialog.hide"
            @close="state.dialog.isOpen=false"
            @update:visible="state.dialog.isOpen = false">

            <div class="dialog-wrap1">
                <div>
                    <label>이미지명 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="imageName" type="text" v-model.trim="state.dataTable.rowDataModel.ImageName" :disabled="!state.dialog.isNew" maxlength="128" />
                    </div>
                </div>
                <div>
                    <label>이미지설명</label>
                    <div>
                        <input class="input-text mr10" type="text" v-model.trim="state.dataTable.rowDataModel.Description" maxlength="256" />
                    </div>
                </div>
                <div>
                    <label>이미지경로 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="imagePath" type="text" v-model.trim="state.dataTable.rowDataModel.ImagePath" :disabled="!state.dialog.isNew" maxlength="128" />
                    </div>
                </div>
                <div>
                    <label>유지기간 <i class="required">*</i></label>
                    <div>
                        <input class="input-text mr10" ref="contentMaintain" type="date" id="icon" v-model="state.dataTable.rowDataModel.ContentMaintain" :showIcon="true"/>
                    </div>
                </div>
            </div>        
            <br>
            <div class="flex-wrap right">
                <div class="ml10">
                    <div class="btn-wrap">
                        <button type="button" class="btn-box gray" v-show="state.dialog.isNew"
                            @click="methods.imageValidation">저장</button>
                        <button type="button" class="btn-box gray" v-show="!state.dialog.isNew"
                            @click="methods.imageValidation">수정</button>
                        <button type="button" class="btn-box red" v-show="!state.dialog.isNew"
                            @click="methods.deleteImageInfo">삭제</button>
                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
import { image } from '@/modules/system/PCM_SM0640E.js';
import { common } from '@/modules/common/common.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';
export default {
    components: {
    },
    setup() {

        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const imageName = ref(); // 이미지명
        const imagePath = ref(); // 이미지경로
        const contentMaintain = ref(); // 유지기간

        // state
        const state = reactive({
            // 데이터 테이블 state
            dataTable: {

                // 이미지 정보 목록
                ImageItems: null,

                // 선택된 시스템코드
                selectedSystemCode: "ADM",

                // 행 데이터 모델
                rowDataModel: new Object(),
            },

            dialog: {

                // 다이얼로그 헤더 텍스트
                header: "메인이미지관리",

                // 다이얼로그 오픈여부
                isOpen: false,

                // 신규 여부
                isNew: false,
            },

            selectBox: {
                items: new Array(),

            },

        });

        // 메서드
        const methods = {

            // 이미지 정보 목록 조회
            getImageInfoList: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                image.getImageInfoList(state.dataTable.selectedSystemCode).then(result => {

                    state.dataTable.ImageItems = result;

                }).finally(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                });
            },

            // 이미지 정보 저장
            saveImageInfo: () => {
                state.dataTable.rowDataModel.Code='';
                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                image.saveImageInfo(state.dataTable.rowDataModel).then((response) => {

                    if(response.data.success) {

                        alertManager.toast.add();

                        // 목록 재조회
                        methods.getImageInfoList();

                    }

                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;

                });
            },

            // 이미지 정보 수정
            updateImageInfo: () => {

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);

                image.updateImageInfo(state.dataTable.rowDataModel).then((response) => {

                    if(response.data.success) {

                        alertManager.toast.save();

                        // 목록 재조회
                        methods.getImageInfoList();

                    }

                }).finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                    // 팝업 닫기
                    state.dialog.isOpen = false;

                });
            },

            // 이미지 정보 삭제
            deleteImageInfo: () => {

                alertManager.confirm.remove().then(() => {

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    image.deleteImageInfo(state.dataTable.rowDataModel).then((response) => {

                        if(response.data.success) {

                            alertManager.toast.remove();

                            // 목록 재조회
                            methods.getImageInfoList();
                        }

                    }).finally(() =>{

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                        // 팝업 닫기
                        state.dialog.isOpen = false;

                    });
                });
            },
            // 이미지 정보 유효성검사
            imageValidation: () => {

                let validator = state.dataTable.rowDataModel;
                // 이미지명
                if (!validator.ImageName) {
                    imageName.value.focus();
                    alertManager.alert.warning('이미지명을 입력해주세요');
                }
                // 이미지 경로
                else if (!validator.ImagePath) {
                    imagePath.value.focus();
                    alertManager.alert.warning('이미지 경로를 입력해주세요');
                }
                // 유지기간
                else if (!validator.ContentMaintain) {
                    contentMaintain.value.focus();
                    alertManager.alert.warning('유지기간을 입력해주세요');
                }
                else {
                    // 이미지 상세 저장
                    if(state.dialog.isNew) alertManager.confirm.save().then(() => { methods.saveImageInfo() });
                    else alertManager.confirm.edit().then(() => { methods.updateImageInfo() });
                }
            },
        }

        // 이벤트 핸들러
        const eventHandler = {

            // 데이터 테이블 관련 이벤트 핸들러
            dataTable: {

                // 행 클릭 이벤트 처리
                onRowClick: event => {

                    // 행 데이터 모델 설정
                    state.dataTable.rowDataModel = Object.assign({}, event.data);

                    // 팝업 오픈
                    state.dialog.isOpen = true;
                },
            },

            // 다이얼로그 관련 이벤트 핸들러
            dialog: {

                // show 이벤트 처리
                show: () => {

                    // 신규 다이얼로그 모드
                    if(state.dialog.isNew) {

                        //선택한 행 시스템코드 값 가져오기
                        state.dataTable.rowDataModel.SystemCode = state.dataTable.selectedSystemCode;

                    }
                },

                // hide 이벤트 처리
                hide: () => {

                    // 행 데이터모델 초기화
                    state.dataTable.rowDataModel = new Object();
                    state.dialog.isNew = false;

                },
            },
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 이미지 정보 초기조회
            methods.getImageInfoList();

            // 시스템 코드 초기조회
            common.getSystemCodeInfo('SYSTEM', '')
                .then(result => state.selectBox.items = result);
        });

        return {
            state,
            image,
            methods,
            eventHandler,
            imageName,
            imagePath,
            contentMaintain,
        }
    }
}
</script>